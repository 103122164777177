<template>
    <div v-if="user && logs" class="animated fadeIn">
        <ibox>
            <ibox-content>
                <b-table striped hover bordered :fields="fields" :items="logs">
                    <template v-slot:cell(point)="data">
                        <b-badge :variant="data.item.point > 0 ? 'primary' : 'danger'" v-if="data.item.point"><span v-if="data.item.point > 0">+</span>{{data.item.point}}</b-badge>
                    </template>
                    <template v-slot:cell(module)="data">
                        <span v-if="data.item.module">{{data.item.module.name}}</span>
                        <span v-else-if="data.item.moduleElement && data.item.moduleElement.module">{{data.item.moduleElement.module.name}}</span>
                    </template>
                    <template v-slot:cell(moduleElement)="data">
                        <span v-if="data.item.moduleElement"><i :class="`fa fa-${data.item.moduleElement.type}`"></i> {{data.item.moduleElement.name}}</span>
                    </template>
                    <template v-slot:cell(test)="data">
                        <span v-if="data.item.test">{{data.item.test.name}}</span>
                    </template>
                </b-table>
            </ibox-content>
        </ibox>
    </div>
</template>

<script>
    /*import BreadCrumbs from "../BreadCrumbs";*/
    import Ibox from "../../components/Ibox/Ibox";
    import IboxContent from "../../components/Ibox/IboxContent";
    import ApiService from "../../services/api.service";
    export default {
        name: "UserStatTable",
        components: {IboxContent, Ibox},
        //components: {BreadCrumbs},
        props: ['user', 'course'],
        data() {
            return {
                filter: "",
                stat: null,
                fields: [
                    {
                        key: 'point',
                        label: 'Баллы'
                    },
                    {
                        key: 'comment',
                        label: 'Описание'
                    },
                    {
                        key: 'module',
                        label: 'Модуль'
                    },
                    {
                        key: 'moduleElement',
                        label: 'Элемент модуля'
                    },
                ],
                logs: null
            }
        },
        mounted() {
            ApiService.get(`course_user_logs`, {params: {'user.id': this.user.id, "course.id": this.course}}).then(res => {
                this.logs = res.data;
                console.log(this.logs)
            })
        }
    }
</script>

<style scoped>

</style>
