<template>
    <div v-if="items" class="row wrapper border-bottom white-bg page-heading">
        <div class="col-lg-12">
            <h2>{{ title }}</h2>
            <ol class="breadcrumb">
                <li v-for="(item, index) in items" v-bind:key="index" class="breadcrumb-item">
                    <router-link :to="item.url" v-slot="{href, navigate}">
                        <a v-if="href === $router.currentRoute.path" @click="()=>{location.reload()}" :href="href" v-html="item.title"></a>
                        <a v-else :href="href" @click="navigate" v-html="item.title"></a>
                    </router-link>
                </li>
                <li class="breadcrumb-item active">
                    <strong v-html="title"></strong>
                </li>
            </ol>
        </div>
    </div>
</template>

<script>
    export default {
        name: "BreadCrumbs",
        props:['title', 'items']
    }
</script>

<style scoped>
    .search-course {
        margin-top: 30px;
    }
</style>
