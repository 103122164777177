<template>
    <div v-if="action">
        <b-badge v-if="action.type === 'video'" variant="warning"><i class="fa fa-file-movie-o"></i> {{ action.title }}</b-badge>
        <b-badge v-else variant="success"><i class="fa fa-file"></i> {{ action.title }}</b-badge>
    </div>
</template>

<script>
    export default {
        name: "LastElement",
        props: ['action']
    }
</script>

<style scoped>

</style>
